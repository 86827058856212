import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const NotFoundError = ({ location }) => {
  return (
    <Layout>
      <Seo
        pagetitle="404 Not Found"
        pagedesc="ページが見つかりません | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
        pagepath={location.pathname}
      />
      <Breadcrumb title="ページが見つかりません" />
      <main className="information">
        <section className="information__section l-container--m">
          <h1 className="information__title">
            お探しのページが見つかりませんでした
          </h1>
        </section>
      </main>
    </Layout>
  )
}

export default NotFoundError
